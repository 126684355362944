import React, { Component } from "react"
import FormMaker from "../../../components/formMaker"
import { Steps } from "intro.js-react";
import IntroWrapper from '../../../components/client-only-wrapper';

export default class PromotionFlyerPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      options: {
        showProgress: true,
        dontShowAgain: true,
        showBullets: false,
        scrollToElement: false,
      },
      showUsers: true,
      initialStep: 0,
      steps: [
        {
          element: "#promotions",
          title: "Promotion",
          intro:
            "When you want to create a special or product promotion flyer you need to have used the Pre approval request form. Once you have approval you can attach this approval here by selecting it from the drop down tab.",
          position: "left",
          tooltipClass: "intro-title",
        },
        {
          element: ".fileupload",
          title: "Upload File",
          intro:
            "This section is if you have a special promotional giveaway that you would like added to your flyer such as a bag / or ear pods or a mask etc ( it needs to be the best quality and resolution image you are able to provide and if possible to be photographed on a plain background)",
          position: "left",
          tooltipClass: "intro-title",
        },
        {
          element: ".submitLater",
          title: "NOTE:",
          intro:
            "If you are waiting for pre-approval then you can save what you have done so far and come back to it later. Just remember that for it to be sent to head office you have to select the 'submit form' button",
          position: "top",
          tooltipClass: "intro-title",
        },
      ],
    }
  }

  toggleSteps = () => {
    this.setState(prevState => ({ stepsEnabled: !prevState.stepsEnabled }))
  }

  onExit = () => {
    this.setState(() => ({ stepsEnabled: false }))
  }
  render() {
    const {
      stepsEnabled,
      steps,
      initialStep,
      options,
      scrollToElement,
    } = this.state
    console.log(this.props)
    return (
      <div>
        <IntroWrapper>
          <Steps
            scrollToElement={scrollToElement}
            options={options}
            enabled={stepsEnabled}
            steps={steps}
            initialStep={initialStep}
            onExit={this.onExit}
          />
        </IntroWrapper>

        <button
          className="fixed bottom-20 right-20 h-28 w-28 bg-brand-blue rounded-full text-white font-bold flex items-center justify-center shadow-lg z-50 font-medium"
          onClick={this.toggleSteps}
        >
          <span className="font-medium">Need Help?</span>
        </button>

        <FormMaker
          submitLater="submitLater"
          fileupload="fileupload"
          promotions="promotions"
          formId="602d8ed6ba5f15001bb52f97"
          formType="product-specials-flyers"
        />
      </div>
    )
  }
}
